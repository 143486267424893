.game-new {
  text-align: center;
}

.game-new input, 
.game-new textarea {
  border: 2px solid #ccc;
  text-align: center;
}

.game-new .share-url {
  width: 50rem;
  text-align: center;
  border: 2px solid #ccc;
  font-size: 1.3rem;
  padding: 1.5rem;
  margin: 2rem;
}

.game-new textarea {
  max-width: 25rem;
  font-size: 1.7rem;
  height: 14rem;
  margin: 2rem;
}

.button-large {
  font-size: 1.4rem;
  height: 4.7rem;
  line-height: 4.6rem;
  padding: 0 2rem;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ccc;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


.letter-spinner {
  width: 1em;
  height: 1em;
  overflow: hidden;
  line-height: 1em;
  display: inline-block;
}
.letter-spinner span {
  position: relative;
}

.letter-spinner span.animate {
  -webkit-animation: spinit 0.2s 5;
  -moz-animation: spinit 0.2s 5;
  animation: spinit 0.2s 5;
}

.letter--container {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  background: #F2C14E;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 1;
  width: 3.3rem;
  height: 3.3rem;
  box-shadow: 0px 1px 1px rgba(0,0,0,0.2);

  text-align: center;
}

@-webkit-keyframes spinit {
  0% {
    top: 0em;
  }
  50% {
    top: -5em;
  }
  100% {
    top: -9em;
  }
}
@-moz-keyframes spinit {
  0% {
    top: 0em;
  }
  50% {
    top: -5em;
  }
  100% {
    top: -9em;
  }
}
@keyframes spinit {
  0% {
    top: 0em;
  }
  50% {
    top: -5em;
  }
  100% {
    top: -9em;
  }
}


.sheet table input[disabled] {
  background-color: #eee !important;
}

.sheet table input[type="text"] {
  text-transform: uppercase;
}


.sheet {
  margin: 2rem;
}

.sheet table {
  table-layout: fixed;
}

.sheet table th.letter {
  width: 1.5rem;
}

.sheet table th {
  width: 5rem;
}

.sheet table td {
  vertical-align: top;
  text-transform: uppercase;
}

.sheet--next {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  transition: all 1s;

  background: #fff;
  box-shadow: -1px 0 5px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1.5rem;
}

.sheet--next[data-visible=true] {
  bottom: 0;
}

.sheet--next button {
  margin: 0 0 0 3rem;
}

.topic-scores {
  display: flex;
}


.topic-scores span {
  border-radius: 50%;
  background: #999;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  height: 2.4rem;
  margin: 2px;
  text-align: center;
  width: 2.4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 150ms ease-in-out;

  font-variant-numeric: tabular-nums;
}

.topic-scores span:hover {
  background: #c974fd;
}

.topic-scores span:active,
.topic-scores span.current {
  background: #9b4dca;
}

#root {
  height: 100%;
}

.room-container {
  margin: 0 0 5rem 0;
}


.home {
  background: #eee;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.home--container {
  text-align: center;
  position: relative;
}

.home--box {
  border: 2px solid #ccc;
  padding: 3rem;
  border-radius: .4rem;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
  font-size: 2rem;
  display: inline-block;
  margin: 4rem 0;
}

.home--disclaimer {
  margin: 0 auto;
  color: #999;
  max-width: 50rem;
}

.home button {
  font-size: 1.7rem;
  line-height: 1.9;
  padding: 1.3rem 3rem;
  height: auto;
}


.home--logo svg {
  width: 20rem;
  height: auto;
}


.modal--overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}


.modal--content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  outline: none;

  background: #fff;
  border-radius: 1rem;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
  padding: 4rem;

  z-index: 1000;
}

.modal--content svg {
  float: right;
  width: 5rem;
  height: auto;
}

.modal--content input,
.modal--content button {
  margin: 0;
  vertical-align: middle;
}

.modal--content input {
  font-size: 2rem;
  width: 25rem;
  font-size: 2rem;
  margin: 0 2rem 0 0;
  height: 4.7rem;
}

.modal--content form {
  text-align: center;
  margin: 0;
}

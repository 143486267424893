html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input[type=text] {
  font-size: 14px;
  padding: 0.8rem 1.2rem;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

textarea {
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

h1 {
  font-size: 3.5rem;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

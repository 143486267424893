.video--participants {
  display: flex;
  justify-content: center;
  list-style-type: none;

  background: #eee;
  box-shadow: 0px -3px 3px inset rgba(0,0,0,0.05);

  padding: 1rem;
  
  min-height: calc(2rem + 112.5px);
}


.video--participants li {
  margin: 0 1rem;
}


.participant {
  position: relative;
}


.participant .video {
  width: 150px;
  height: 112.5px;
  display: block;
  background: #ccc;

  border-radius: 1rem;

  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}

.participant .video video {
  border-radius: 1rem;
  max-width: 100%;
  max-height: 100%;
  display: block;
}


.participant .details {
  border-radius: 0 0 1rem 1rem;
  display: flex;
  justify-content: space-between;

  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 0.4rem 0.6rem;
  font-size: 1.2rem;

  line-height: 1.2;
}


.participant .score {
  font-variant-numeric: tabular-nums;
}
